import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import '@rails/actiontext'
import 'trix'

import './channels'
import './controllers'

// Our packages
import './javascripts/user/turbolinks_prepare_for_cache'
import './javascripts/shared/map_init'

// Vendors
import smoothscroll from 'smoothscroll-polyfill'

ActiveStorage.start()

document.addEventListener('turbo:load', (event) => {
  smoothscroll.polyfill()
})
